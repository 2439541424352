import request from '@/utils/request'

export function fetchAdvertiseList(data) {
    return request({
        url: '/advert/appCategoryAdvertise/advertiseList',
        method: 'post',
        data
    })
}

export function AdvertiseDel(data) {
    return request({
        url: '/advert/appAdvertise/del',
        method: 'post',
        data
    })
}


export function appAdvertiseModify(data) {
    return request({
        url: '/advert/appAdvertise/add',
        method: 'post',
        data
    })
}


export function appAdvertiseCheck(data) {
    return request({
        url: '/advert/appCategoryAdvertise/checkList',
        method: 'post',
        data
    })
}