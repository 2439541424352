<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" >
      <el-breadcrumb-item>应用管理</el-breadcrumb-item>
      <el-breadcrumb-item>广告列表管理</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <el-row>
      <!-- <el-button type="primary" @click="handleCreate">添加</el-button>
      <el-button type="primary" @click="handleFoms">手动录入</el-button> -->
        <el-col :span="3">
            <el-select v-model="listQuery.name" clearable  placeholder="app" style="margin-right: 30px">
                <el-option
                    v-for="item in appOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col>

        <el-col :span="3">
            <el-select v-model="listQuery.id" placeholder="广告品类" clearable style="margin-right: 30px">
                <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col>

        <!-- <el-col :span="3" style="margin-right: 30px">
            <el-input v-model="listQuery.power" placeholder="权重配置"></el-input>
        </el-col> -->

        <!-- <el-col :span="3" style="margin-right: 30px">
            <el-select v-model="listQuery.tagId" placeholder="投放标签" clearable>
                <el-option
                    v-for="item in tagListAll"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-col> -->

        <el-col :span="3" style="margin-right: 30px">
            <!-- <el-button type="primary">确定</el-button> -->
            <el-button type="primary" @click="search">查询</el-button>
            <el-button type="primary" @click="configMidify" v-if="checkedList && checkedList.length > 0">确定</el-button>
        </el-col>
    </el-row>


    <!--表单 BEGIN -->
    <!-- <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px" style="width: 600px; margin-left:50px;">

        <el-form-item label="应用" prop="appId">
          <el-select v-model="temp.appName" value-key="id" :disabled="dialogStatus === 'update'" @change="appIdChange"  style="width:500px"   placeholder="应用">
            <el-option
                v-for="item in appOptions"
                :key="item.id"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="广告位" prop="adId">
          <el-select v-model="temp.adName" value-key="id" :disabled="dialogStatus === 'update'"  style="width:500px" placeholder="广告位">
            <el-option
                v-for="item in adOptions"
                :key="item.id"
                :label="item.adName"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="日期" prop="dateYmd" label-width="100px">

          <el-date-picker
              v-model="temp.dateYmd"
              v-bind:disabled="dialogStatus === 'update'"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="实际收入" prop="actualRevenue">
          <el-input v-model="temp.actualRevenue" @change="changeMoney"/>
        </el-form-item>
        <el-form-item label="实收比率(千分比)" prop="actualRatio">
          <el-input v-model="temp.actualRatio" @change="changeMoney"/>
        </el-form-item>
        <el-form-item label="展示收入" prop="showRevenue">
          <el-input v-model="temp.showRevenue" disabled />
        </el-form-item>
        <el-form-item label="ecpm" prop="ecpm">
          <el-input v-model="temp.ecpm" disabled />
        </el-form-item>
        <el-form-item label="请求量" prop="askNumber">
          <el-input v-model="temp.askNumber" />
        </el-form-item>
        <el-form-item label="曝光量" prop="exposureNumber">
          <el-input v-model="temp.exposureNumber" @change="changeExposure"/>
        </el-form-item>
        <el-form-item label="点击量" prop="clickNumber">
          <el-input v-model="temp.clickNumber" />
        </el-form-item>
        <el-form-item label="点击率" prop="clickRate">
          <el-input v-model="temp.clickRate" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog> -->

    <!--表单 END -->
    <br />
    <div style="background: #fff ">
    <el-table
        v-loading="loadings"
        ref="singleTable"
        :data="listData"
        border
        fit
        style="width: 100%;height: 100%"
    >
      <el-table-column
          fixed
          fit
          align="center"
          type="index"
          min-width="20">
      </el-table-column>

      <el-table-column prop="appName" label="app名称" min-width="40" />
      <el-table-column prop="categoryName" label="广告品类名称" min-width="40" />
      <el-table-column prop="advertiseName" label="广告位名称" min-width="60" />
      <!-- <el-table-column prop="createTime" label="创建日期" min-width="40" /> -->

      <!-- <el-table-column prop="actualRevenue" label="实际收入" min-width="40" />

      <el-table-column prop="actualRatio" label="实收（千分比）" min-width="40" /> -->

      <!-- <el-table-column prop="clickRate" label="点击率（百分比）" min-width="40" >
        <template  slot-scope="scope">
          {{ scope.row.clickRate ? scope.row.clickRate : 0}}%
        </template>
      </el-table-column> -->


      <!-- <el-table-column label="操作" align="center" min-width="60" >
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleUpdate(scope.$index, scope.row)">编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column> -->

      <el-table-column label="是否指定投放" align="center" min-width="50">
        <template slot-scope="scope">
          <el-checkbox v-model="scope.row.cp" @change="change(scope.row,scope.$index)"></el-checkbox>
        </template>
      </el-table-column>
      
      <!-- <el-table-column label="是否指定投放" align="center" min-width="50">
        <template slot-scope="scope">
          <el-switch
            style="display:block;"
            v-model="scope.row.cp"
            @change="change(scope.row,scope.$index)"
            active-text="是"
            inactive-text="否">
          </el-switch>
        </template>
      </el-table-column> -->

    </el-table>

    <el-pagination style="background: #fff ;margin-top: 20px;margin-left: 10px"
                   background @current-change="handleCurrentChange" :page-size="listQuery.size" layout="total,prev, pager, next" :total="total">
    </el-pagination>
    <div style="height: 30px;" />

    </div>


  </div>
</template>

<script>

import { listAllApi } from "@/api/apps";
import { tagListAll, categoryListAll } from '@/api/ads'
import { fetchAdvertiseList, AdvertiseDel,appAdvertiseCheck } from '@/api/adlist'

export default {
  name: "appProfit",
  components: {  },
  inject: ['load','closeLoad'],
  data() {
    return {
      total: 0,
      listQuery: {
        current: 1,
        size: 10,
        dateYMd:'',
        appId:'',
        id:'',
        power:'',
        name:'',
        tagId:''
      },
      switchValue1: false,
      dialogFormVisible: false,
      dialogFormVisible1:false,
      dialogStatus: '',
      dialogStatus1:'',
      textMap: {
        update: '编辑',
        create: '添加'
      },
      rules: {
        bgId: [{ required: true, message: '不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        sex: [{ required: true, message: '不能为空', trigger: 'blur' }],
        phone: [{ required: true, message: '不能为空', trigger: 'blur' }],
        wechat: [{ required: true, message: '不能为空', trigger: 'blur' }],
        qq: [{ required: true, message: '不能为空', trigger: 'blur' }],
        email: [{ required: true, message: '不能为空', trigger: 'blur' }],

      },

      temp: {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',

      },
      fomrs:{
        appId: '',
        dateYMd: '',
        adId:''
      },
      loadings:false,
      appName:'',
      tableData: [],
      appOptions:[],
      adOptions:[],
      companyOptions:[],
      qadOptions:[],
      categoryList:[],//品类列表
      tagListAll:[],//标签列表
      listData:[],
      testArr1: [],
      testArr2: [],
      testArr3: [],
      testPosition1: 0,
      testPosition2: 0,
      testPosition3:0,
      checkedList:[],
      sexOptions:[{
        id: 1,
        label: '男'
      },{
        id: 2,
        label: '女'
      }],
    }
  },
  created() {
    // this.getList();
    this.getInitOptions();
    this.getCategoryListAll()
    this.getTagListAll()
  },
  methods:{
    getAdvertiseList() {
      let para = {
        current: this.listQuery.current,
        size: this.listQuery.size,
        appId:this.listQuery.name,
        categoryId:this.listQuery.id
      }
      fetchAdvertiseList(para).then(response => {
          if(response.code === 1) {
              this.closeLoad()
              let datas = response.body.records
              datas.forEach((item)=>{
                item.cp = item.checked ? true : false
              })
              this.listData = datas
              this.total = response.body.total
          }
      })
    },
    search() {
      if(!this.listQuery.name) {
        this.$message({
          showClose: true,
          message: '请选择app应用',
          type: 'warning'
        });
        return
      }
      this.load()
      this.listQuery.current = 1
      this.getAdvertiseList()
    },
    getInitOptions(){
      listAllApi({current: 1,size: 1000,}).then(response => {
          if(response.code === 1) {
              this.appOptions = response.body.records
          }
      })
    },
    getCategoryListAll() {
      categoryListAll({}).then(response => {
          if(response.code === 1) {
              this.categoryList = response.body
          }
      })
    },
    // qappIdChange(val){
    //     console.log(1212121)
    // //     console.log(val)
    // //   广告位
    // //   this.listQuery.adId='';

    // //   this.listQuery.current = 1;
    // //   this.listQuery.appId = val.id
    // //   this.appName = val.name
      
    // //   listAllByAppId({appId: val.id}).then(response => {
    // //     console.log(response)
    // //     this.qadOptions = response.body
    // //     this.getList();
    // //   })
    // },
    getTagListAll() {
      tagListAll({}).then(response => {
        // console.log(response)
        if(response.code === 1) {
            this.tagListAll = response.body
        }
      })
    },
    // appIdChange(val){
    //   console.info("广告位")
    //   console.info(val)
    //   //广告位
    //   this.temp.adId='';
    //   console.log(val.id)
    //   listAllByAppId({appId: val.id}).then(response => {
    //     this.adOptions = response.body
    //   })
    // },

    // qadIdChange(val){
    //   console.info(val)
    //   this.listQuery.current = 1;
    //   this.getList();

    // },
    change(data,val) {
      let arrs = []
      let state = true
      if(this.checkedList && this.checkedList.length > 0) {
        for (let i = 0; i < this.checkedList.length; i++) {
          if(this.checkedList[i].advertiseId == data.advertiseId) {
              state = false
              this.checkedList.splice(i,1)
              break;
          }
        }
        if(state) {
          arrs.push(data)
        }
      } else {
        arrs.push(data)
      }
      this.checkedList = [...this.checkedList, ...arrs]
  
    },
    configMidify() {
       this.$confirm('是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.checkedList.forEach((item)=>{
          if(item.cp) {
            item.checked = 1
          } else {
            item.checked = 0
          }
        })
        appAdvertiseCheck(this.checkedList).then(response => {
          if(response.code === 1) {
              this.load()
              this.checkedList = []
              this.getAdvertiseList()
          }
        })
      }).catch(() => {});
    },
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getAdvertiseList()
    },
    handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    handleFoms() {
      this.dialogFormVisible1 = true
      this.resetFomes()
      this.dialogStatus1 = 'create'
      this.$nextTick(() => {
        this.$refs['dataForms'].clearValidate()
      })
    },
    datechange(){
      console.log(this.listQuery.dateYMd);
      // this.dateYMd = this.listQuery.dateYMd
      this.listQuery.current = 1;
    //   this.getList();

    },
    resetFomes() {
      this.fomrs = {
        appId: '',
        dateYMd: '',
        fomrs:''
      }
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        appId: '',
        adId: '',
        dateYMd: '',
        actualRevenue: '',
        actualRatio: '',
        showRevenue: '',
        ecpm: '',
        askNumber: '',
        exposureNumber: '',
        clickNumber: '',
        clickRate: '',
        adName: '',
      }
    },

    // handleDelete(index, row) {
    //   console.log(index, row);
    //   this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     AdvertiseDel({id: row.id}).then(() => {
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       });
    //       this.getAdvertiseList()
    //       // this.tableData.splice(index, 1)
    //     })

    //   }).catch(() => {});
    // }

  }
}

</script>

<style scoped>


</style>
